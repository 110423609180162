<template>
  <div v-if="fabricComposition">
    <trace-component-header
      title="Fabric Composition"
      icon="fabric-composition-icon"
      :colorCodes="colorCodes"
      :isCompact="isCompact"
      :isMobile="isMobile"
    >
      <div
        :class="`more-info-data${isMobile ? '-mobile' : ''} accept-line-breaks`"
        :style="{ color: colorCodes.fontColor }"
      >
        {{ fabricComposition }}
      </div>
    </trace-component-header>
  </div>
  <div v-else-if="careInstructions && careInstructions.length">
    <trace-component-header
      title="Care Instructions"
      icon="care-instruction-icon"
      :colorCodes="colorCodes"
      :isCompact="isCompact"
      :isMobile="isMobile"
      :iconSize="24"
    >
      <div
        :class="`more-info-data${isMobile ? '-mobile' : ''}`"
        :style="{ color: colorCodes.fontColor }"
      >
        <div style="min-height: 40px; margin-bottom: 10px; width: 85%">
          <span
            v-for="(careInstruction, index) in careInstructions"
            :key="`careInstruction${index}`"
          >
            <img
              class="care-instruction-img"
              :style="careInstructionColorManipulation"
              v-if="careInstruction.icon"
              :src="careInstruction.icon"
            />
          </span>
        </div>
        <div
          v-for="(careInstruction, index) in careInstructions"
          :key="`careInstruction${index}`"
        >
          <span>{{ careInstruction.care_instruction_name }}</span>
        </div>
      </div>
    </trace-component-header>
  </div>
  <div v-else-if="displaySupplyChain && supplyChain && supplyChain.length">
    <trace-component-header
      title="Supply Chain"
      icon="supply-chain-icon"
      :colorCodes="colorCodes"
      :isCompact="isCompact"
      :isMobile="isMobile"
      :iconSize="24"
    >
      <div
        :style="`${
          isMobile || isCompact
            ? `margin-left: ${isMobile ? '15' : '74'}px; margin-top: 20px;`
            : 'margin-top: 65px;'
        }`"
      >
        <div v-for="(trace, index) in supplyChain" :key="`trace${index}`">
          <div
            v-if="trace.factory?.established_country || trace.delivery_method"
          >
            <div class="icon-container">
              <trace-services-icon
                :traceName="trace.icon"
                :variant="colorCodes.iconColor"
                :background="colorCodes.backgroundColor"
              />
              <span
                class="title supply-chain-title"
                :style="{
                  color: colorCodes.titleColor,
                }"
              >
                {{ trace.field_name.toUpperCase() }}
              </span>
            </div>

            <v-row>
              <div
                v-if="stepperShow(index)"
                :class="`stepper${isMobile ? '-mobile' : ''}`"
                :style="`border-image-source: linear-gradient(to bottom, ${colorCodes.iconColor}, ${iconDividerGradientEndColor}) !important;`"
              ></div>
              <v-col cols="12">
                <div class="content">
                  <div v-if="trace.factory">
                    <div
                      class="data-field details-subtitle"
                      v-if="trace.factory.established_country"
                      :style="{
                        color:
                          !entireTraceUnverified && trace.verified
                            ? colorCodes.verifiedColor
                            : colorCodes.unverifiedColor,
                      }"
                    >
                      Country:
                      <span
                        class="details-data"
                        :style="{
                          color:
                            !entireTraceUnverified && trace.verified
                              ? colorCodes.verifiedColor
                              : colorCodes.unverifiedColor,
                        }"
                      >
                        {{ trace.factory.established_country }}
                      </span>
                    </div>
                    <div
                      class="data-field details-subtitle"
                      v-if="trace.factory.established_city"
                      :style="{
                        color:
                          !entireTraceUnverified && trace.verified
                            ? colorCodes.verifiedColor
                            : colorCodes.unverifiedColor,
                      }"
                    >
                      City:
                      <span
                        class="details-data"
                        :style="{
                          color:
                            !entireTraceUnverified && trace.verified
                              ? colorCodes.verifiedColor
                              : colorCodes.unverifiedColor,
                        }"
                      >
                        {{ trace.factory.established_city }}
                      </span>
                    </div>
                    <div
                      class="data-field details-subtitle"
                      v-if="trace.factory.number_of_employees"
                      :style="{
                        color:
                          !entireTraceUnverified && trace.verified
                            ? colorCodes.verifiedColor
                            : colorCodes.unverifiedColor,
                      }"
                    >
                      Employees:
                      <span class="data"
                        >{{ trace.factory.number_of_employees }}
                        {{ formatEmployees(trace.factory) }}</span
                      >
                    </div>
                    <div v-if="trace.factory?.certifications.length">
                      <div
                        class="data-field details-subtitle"
                        :style="{
                          color:
                            !entireTraceUnverified && trace.verified
                              ? colorCodes.verifiedColor
                              : colorCodes.unverifiedColor,
                        }"
                      >
                        Certifications:
                      </div>
                      <div class="certifications" tabindex="0">
                        <div
                          class="certification-list"
                          :style="
                            !entireTraceUnverified && trace.verified
                              ? verifiedCertificationStyles
                              : unverifiedCertificationStyles
                          "
                          v-for="(certification, index) in trace.factory
                            .certifications"
                          :key="`trace.certifications${index}`"
                        >
                          <div v-if="!certification.description">
                            {{ certification.caption }}
                          </div>
                          <v-tooltip
                            v-else
                            top
                            :color="
                              !entireTraceUnverified && trace.verified
                                ? colorCodes.verifiedColor
                                : colorCodes.unverifiedColor
                            "
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <div data-app>
                                <div v-bind="attrs" v-on="on">
                                  {{ certification.caption }}
                                </div>
                              </div>
                            </template>
                            <div class="description-child-container">
                              <div class="mt-1">
                                {{ certification.description }}
                              </div>
                            </div>
                          </v-tooltip>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="data-field details-subtitle"
                    v-else-if="trace.delivery_method"
                    :style="{
                      color:
                        !entireTraceUnverified && trace.verified
                          ? colorCodes.verifiedColor
                          : colorCodes.unverifiedColor,
                    }"
                  >
                    Method:
                    <span
                      class="details-data"
                      :style="{
                        color:
                          !entireTraceUnverified && trace.verified
                            ? colorCodes.verifiedColor
                            : colorCodes.unverifiedColor,
                      }"
                    >
                      {{
                        deliveryMethods.find(
                          (element) => trace.delivery_method === element.id
                        ).name
                      }}
                    </span>
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>
        </div>
      </div>
    </trace-component-header>
  </div>
  <div v-else-if="sustainability">
    <trace-component-header
      title="Sustainability and Circularity"
      icon="sustainability-icon"
      :colorCodes="colorCodes"
      :isCompact="isCompact"
      :isMobile="isMobile"
      :iconSize="24"
    >
      <div
        :class="`more-info-data${isMobile ? '-mobile' : ''} accept-line-breaks`"
        :style="{ color: colorCodes.fontColor }"
        v-html="sustainability"
        v-linkified
      ></div>
    </trace-component-header>
  </div>
  <div v-else-if="lcaValues && Object.keys(lcaValues).length">
    <trace-component-header
      title="Life Cycle Assessments"
      icon="l-c-a-icon"
      :colorCodes="colorCodes"
      :isCompact="isCompact"
      :isMobile="isMobile"
      :iconSize="24"
    >
      <v-app>
        <div :style="`${isMobile ? 'margin-top: 30px;' : ''}`">
          <div class="lca-flexbox">
            <l-c-a-view
              :lcaValues="lcaValues"
              :isCompact="isCompact"
              :isMobile="isMobile"
              :colorCodes="colorCodes"
            />
          </div>
        </div>
      </v-app>
    </trace-component-header>
  </div>
  <div
    v-else-if="repairRecycleResaleHeaders && repairRecycleResaleHeaders.length"
  >
    <trace-component-header
      title="Donation, Repair, Upcycle, Recycle and Resale"
      icon="repair-recycle-resale-icon"
      :colorCodes="colorCodes"
      :isCompact="isCompact"
      :isMobile="isMobile"
      :iconSize="24"
    >
      <v-app>
        <div>
          <div
            :class="`${
              isMobile
                ? !isCompact
                  ? 'repair-resale-recycle-item-flexbox-mobile repair-resale-recycle-margin'
                  : 'repair-resale-recycle-item-flexbox-mobile'
                : 'repair-resale-recycle-item-flexbox'
            }`"
          >
            <div
              v-for="item in repairRecycleResaleHeaders"
              :key="item.title"
              :class="`${
                isMobile || isCompact
                  ? 'repair-resale-recycle-item-wrapper-mobile'
                  : 'repair-resale-recycle-item-wrapper'
              }`"
            >
              <div class="icon-container" style="margin-bottom: 30px">
                <trace-services-icon
                  :traceName="item.icon"
                  :variant="colorCodes.iconColor"
                  :background="colorCodes.backgroundColor"
                />
                <span
                  class="title supply-chain-title"
                  :style="{
                    color: colorCodes.titleColor,
                  }"
                >
                  {{ item.title }}
                </span>
              </div>
              <div
                v-for="itemData in item.data"
                :key="`${item.type}-${itemData.id}`"
              >
                <repair-resale-recycle-item
                  :item="itemData"
                  :type="item.type"
                  :expandWithPopup="!isMobile"
                  :entireTraceUnverified="entireTraceUnverified"
                  :colorCodes="colorCodes"
                ></repair-resale-recycle-item>
              </div>
            </div>
          </div>
        </div>
      </v-app>
    </trace-component-header>
  </div>
  <div v-else-if="bom && bom.length">
    <trace-component-header
      title="Component Traceability"
      icon="bom-item-icon"
      :colorCodes="colorCodes"
      :isCompact="isCompact"
      :isMobile="isMobile"
      :iconSize="24"
    >
      <v-app>
        <div :style="`${isMobile ? 'margin-top: 30px;' : ''}`">
          <div :class="`${isMobile ? '' : 'bom-items-flexbox'}`">
            <div
              v-for="bomItem in bom"
              :key="bomItem.id"
              :class="`${
                isMobile || isCompact
                  ? 'bom-item-wrapper-mobile'
                  : 'bom-item-wrapper'
              }`"
            >
              <bom-item-trace
                :bomItem="bomItem"
                :expandWithPopup="!isMobile"
                :entireTraceUnverified="entireTraceUnverified"
                :colorCodes="colorCodes"
              ></bom-item-trace>
            </div>
          </div>
        </div>
      </v-app>
    </trace-component-header>
  </div>
  <div v-else-if="isReviewComponent">
    <trace-component-header
      title="Customer Feedback"
      icon="customer-feedback-icon"
      :colorCodes="colorCodes"
      :isCompact="isCompact"
      :isMobile="isMobile"
      :iconSize="24"
    >
      <review
        :styleNumber="styleNumber"
        :isAddReviewVisible="isAddReviewVisible"
        :preview="isPreview"
        :isMobile="isMobile"
        :isCompact="isCompact"
        :colorCodes="colorCodes"
      ></review>
    </trace-component-header>
  </div>
</template>

<script>
import TraceComponentHeader from "./TraceComponentHeader.vue";
import BomItemTrace from "../Inquiry/BomItemTrace.vue";
import Review from "./Review.vue";
import LCAView from "./LCAView.vue";
import { hexToRGBA, isLightColor } from "../../utils/helper";
import * as conf from "../../conf.yml";
import RepairResaleRecycleItem from "./RepairResaleRecycleItem.vue";

export default {
  name: "TraceComponentItem",
  components: {
    TraceComponentHeader,
    BomItemTrace,
    RepairResaleRecycleItem,
    Review,
    LCAView,
  },
  props: {
    fabricComposition: {
      type: String,
      default: "",
    },
    careInstructions: {
      type: Array,
      default: () => [],
    },
    sustainability: {
      type: String,
      default: "",
    },
    displaySupplyChain: {
      type: Boolean,
      default: false,
    },
    supplyChain: {
      type: Array,
      default: () => [],
    },
    bom: {
      type: Array,
      default: () => [],
    },
    lcaValues: {
      type: Object,
      default: () => ({}),
    },
    recycles: {
      type: Array,
      default: () => [],
    },
    repairs: {
      type: Array,
      default: () => [],
    },
    resales: {
      type: Array,
      default: () => [],
    },
    donations: {
      type: Array,
      default: () => [],
    },
    upcycles: {
      type: Array,
      default: () => [],
    },
    colorCodes: {
      type: Object,
      default: () => ({
        titleColor: "#473068",
        fontColor: "#473068",
        iconColor: "#70d0ce",
        verifiedColor: "#473068",
        unverifiedColor: "#ea7a66",
        backgroundColor: "#ffffff",
      }),
    },
    isCompact: {
      type: Boolean,
      default: false,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
    isRepairResaleRecycle: {
      type: Boolean,
      default: false,
    },
    isReviewComponent: {
      type: Boolean,
      default: false,
    },
    isAddReviewVisible: {
      type: Boolean,
      default: false,
    },
    isPreview: {
      type: Boolean,
      default: false,
    },
    styleNumber: {
      type: String,
      default: null,
    },
    deliveryMethods: {
      type: Array,
      default: () => [],
    },
    entireTraceUnverified: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    baseUrl: conf.default.server.host,
  }),

  computed: {
    repairRecycleResaleHeaders() {
      if (!this.isRepairResaleRecycle) {
        return [];
      }
      const headers = [];
      if (this.donations && this.donations.length) {
        headers.push({
          title: "Donation",
          type: "donations",
          icon: "DonationsIcon",
          data: this.donations,
        });
      }
      if (this.repairs && this.repairs.length) {
        headers.push({
          title: "Repair",
          type: "repairs",
          icon: "RepairsIcon",
          data: this.repairs,
        });
      }
      if (this.upcycles && this.upcycles.length) {
        headers.push({
          title: "Upcycle",
          type: "upcycles",
          icon: "UpcyclesIcon",
          data: this.upcycles,
        });
      }
      if (this.recycles && this.recycles.length) {
        headers.push({
          title: "Recycle",
          type: "recycles",
          icon: "RecyclesIcon",
          data: this.recycles,
        });
      }
      if (this.resales && this.resales.length) {
        headers.push({
          title: "Resale",
          type: "resales",
          icon: "ResalesIcon",
          data: this.resales,
        });
      }

      return headers;
    },
    titleDividerGradientEndColor() {
      const endColor = hexToRGBA(this.colorCodes.titleColor);
      return `rgba(${endColor.r}, ${endColor.g}, ${endColor.b}, 0.1)`;
    },
    iconDividerGradientEndColor() {
      const endColor = hexToRGBA(this.colorCodes.iconColor);
      return `rgba(${endColor.r}, ${endColor.g}, ${endColor.b}, 0.1)`;
    },
    verifiedDividerGradientEndColor() {
      const endColor = hexToRGBA(this.colorCodes.verifiedColor);
      return `rgba(${endColor.r}, ${endColor.g}, ${endColor.b}, 0.1)`;
    },
    unverifiedDividerGradientEndColor() {
      const endColor = hexToRGBA(this.colorCodes.unverifiedColor);
      return `rgba(${endColor.r}, ${endColor.g}, ${endColor.b}, 0.1)`;
    },
    verifiedCertificationStyles() {
      return `color: ${this.colorCodes.verifiedColor}; background-color: ${this.verifiedDividerGradientEndColor}; border: 0.5px solid ${this.colorCodes.verifiedColor};`;
    },
    unverifiedCertificationStyles() {
      return `color: ${this.colorCodes.unverifiedColor}; background-color: ${this.unverifiedDividerGradientEndColor}; border: 0.5px solid ${this.colorCodes.unverifiedColor};`;
    },
    careInstructionColorManipulation() {
      const endColor = hexToRGBA(this.colorCodes.backgroundColor);
      const isLight = isLightColor(endColor);
      return isLight
        ? "mix-blend-mode: multiply;"
        : "mix-blend-mode: screen; filter: invert(1) hue-rotate(180deg);";
    },
  },
  methods: {
    stepperShow(index) {
      let showBorder = false;
      const item = this.supplyChain[index];
      if (!item.delivery_method) {
        showBorder = true;
      }
      return showBorder;
    },
    truncateName(name) {
      return name.length > 10 ? name.substring(0, 10) : name;
    },
    formatEmployees(employees) {
      const values = [
        employees.number_of_males,
        employees.number_of_females,
        employees.number_of_others,
      ];
      const genders = ["Males", "Females", "Others"];
      let percentage = "";
      for (let i = 0; i < values.length; i++) {
        percentage = `${percentage}${
          values[i] && parseFloat(values[i])
            ? parseFloat(values[i]) + "%" + " " + genders[i]
            : ""
        }`;
        if (values[i + 1] && percentage) {
          percentage = `${percentage} | `;
        }
      }
      if (percentage) {
        percentage = `(${percentage})`;
      }
      return percentage;
    },
  },
};
</script>

<style lang="scss" scoped>
#app {
  all: unset;
}
.theme--light.v-application {
  all: unset;
}
.v-application {
  all: unset;
}
.v-application::v-deep .v-application--wrap {
  all: unset;
}
.more-info-data {
  margin: 15px 80px 0px 75px !important;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
}
.more-info-data-mobile {
  @extend .more-info-data;
  margin: 25px 80px 0px 5px !important;
  line-height: 25px;
}
.accept-line-breaks {
  white-space: pre-line;
  word-break: break-word;
}
.care-instruction-img {
  width: 50px;
  max-height: 50px;
  margin-right: 15px;
  margin-bottom: 10px;
}
.icon-container {
  display: flex;
  align-items: center;
}
.supply-chain-header {
  margin-left: 10px;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
}
.supply-chain-title {
  margin-top: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px !important;
  line-height: 33px;
  color: #473068;
}
.title {
  margin-left: 15px;
}
.bom-items-flexbox {
  display: flex;
  justify-content: space-between;
}
.repair-resale-recycle-item-flexbox {
  display: flex;
}
.repair-resale-recycle-item-flexbox-mobile {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
.repair-resale-recycle-margin {
  margin-left: 15px;
}

.column {
  padding: 10px;
}
.item-icon-wrapper {
  width: 50px; /* Set your desired width */
  height: 50px; /* Set your desired height */
  display: flex;
  align-items: center;
  justify-content: center;
}

.item-image {
  max-width: 100%;
  max-height: 100%;
}

.item-icon-wrapper {
  margin-top: 10px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background-color: #f0f0f0;
  margin-left: 10px;
  margin-right: 15px;
  border-radius: 4px;
}

.item-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.item-icon {
  font-size: 50px;
  color: #888;
}

.item-info {
  display: flex;
  flex-direction: column;
}
.stepper {
  margin: 21px -43px 18px 40px !important;
  min-height: 50px;
  height: auto !important;
  border-left: 3px solid;
  border-image-slice: 1;
  margin: 100px;
}
.stepper-mobile {
  @extend .stepper;
  margin: 21px -43px 30px 40px !important;
}
.content {
  margin: 0px 0px 25px 75px !important;
  font-size: 14px;
}
.data-field {
  font-size: 16px;
  margin: 5px 0px 5px 0px;
}
.details-subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
}
.details-data {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
}
.certifications {
  width: 200px;
  height: auto;
  margin-left: -5px;
}
.certification-list {
  display: inline-block;
  justify-content: center;
  align-items: center;
  padding: 2px 5px 2px 5px;
  margin: 5px 5px 5px 5px;
  border-radius: 3px;
}
.description-child-container {
  text-align: left !important;
  max-width: 250px !important;
}
.lca-flexbox {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.bom-items-flexbox {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0px 0px 0px 75px;
}
.repair-resale-recycle-item-flexbox {
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
  margin: 0px 0px 0px 75px;
  column-gap: 10px;
}
.bom-item-wrapper-mobile {
  width: 100%;
}
.bom-item-wrapper {
  width: calc(100% / 3);
}
.repair-resale-recycle-item-wrapper-mobile {
  margin-top: 25px;
  width: 100%;
}
.repair-resale-recycle-item-wrapper {
  margin-top: 25px;
  width: calc((100% - 20px) / 3);
}
.more-info-data::v-deep p,
.more-info-data-mobile::v-deep p {
  margin-bottom: unset;
}
@media only screen and (max-width: 950px) {
  .bom-item-wrapper {
    width: calc(100% / 2);
  }
  .repair-resale-recycle-item-wrapper {
    width: calc((100% - 10px) / 2);
  }
}

@media only screen and (min-width: 1512px) {
  .bom-item-wrapper {
    width: calc(100% / 4);
  }
}
</style>

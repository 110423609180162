<template>
  <div v-if="Object.keys(availableLcaValues).length" :class="layoutClass">
    <div
      v-for="(value, key) in availableLcaValues"
      :key="key"
      :class="cardClass"
      :style="cardStyles"
    >
      <div class="icon-container">
        <component
          :is="icons[key]"
          width="80%"
          height="80%"
          :fill="colorCodes.iconColor"
        />
      </div>
      <div class="content-container">
        <div class="lca-title" :style="{ color: colorCodes.titleColor }">
          {{ labels[key] }}
        </div>
        <div class="value" :style="{ color: colorCodes.fontColor }">
          {{ value }} {{ units[key] }}
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="no-values">No LCA data available</div>
  </div>
</template>

<script>
import { hexToRGBA } from "../../utils/helper";

export default {
  name: "LCAView",
  props: {
    lcaValues: {
      type: Object,
      default: null,
    },
    isCompact: {
      type: Boolean,
      default: false,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
    colorCodes: {
      type: Object,
      default: () => ({
        titleColor: "#473068",
        fontColor: "#000000",
        iconColor: "#473068",
        verifiedColor: "#473068",
        unverifiedColor: "#ea7a66",
        backgroundColor: "#ffffff",
      }),
    },
  },

  data() {
    return {
      borderColor: "rgba(96, 39, 160, 0.31)",
      labels: {
        co2: "Carbon Dioxide Emission",
        water: "Water Usage",
        energy: "Energy Consumption",
      },
      icons: {
        co2: "co2-icon",
        water: "water-drop-icon",
        energy: "energy-icon",
      },
      units: {
        co2: "kg",
        water: "m³",
        energy: "MJ",
      },
    };
  },
  computed: {
    availableLcaValues() {
      if (this.lcaValues === null) {
        return {};
      }
      return Object.keys(this.lcaValues).reduce((acc, key) => {
        if (this.lcaValues[key] !== null) {
          acc[key] = this.lcaValues[key].toFixed(5);
        }
        return acc;
      }, {});
    },
    layoutClass() {
      return this.isMobile ? "mobile" : this.isCompact ? "compact" : "expand";
    },
    cardClass() {
      return `card-${this.isMobile || this.isCompact ? "compact" : "expand"}`;
    },
    cardStyles() {
      return {
        border: `1px solid ${this.borderColor}`,
        backgroundColor: this.colorCodes.backgroundColor,
      };
    },
  },
  watch: {
    colorCodes: {
      immediate: true,
      handler(value) {
        if (value) {
          const rgbaValue = hexToRGBA(value.iconColor || "#473068");
          this.borderColor = `rgba(${rgbaValue.r}, ${rgbaValue.g}, ${rgbaValue.b}, 0.31)`;
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.mobile {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 10px 10px;
}

.expand {
  display: flex;
  flex: 1;
  margin: 10px 70px;
  flex-wrap: wrap;
  gap: 10px;
}

.compact {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 10px 70px;
}

.card-expand,
.card-compact {
  box-sizing: border-box;
  height: 84px;
  min-width: 255px;
  min-height: 60px;
  max-height: 84px;
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
}

.card-expand {
  width: 30%;
}

.card-compact {
  width: 100%;
}

.icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.content-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.lca-title {
  min-height: 24px;
  max-height: 24px;
  font-size: 15px;
  font-weight: 600;
  text-align: right;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.value {
  min-height: 31px;
  max-height: 31px;
  font-size: 25px;
  font-weight: 500;
  text-align: right;
}

.no-values {
  font-size: 15px;
  margin-top: 20px;
  color: rgba(0, 0, 0, 0.6);
  text-align: left;
}
</style>

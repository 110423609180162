<template>
  <div class="compact-container flex-raw">
    <div
      v-for="column in isMobile ? [1] : [1, 2]"
      class="flex-column"
      :key="`column-${column}`"
    >
      <div
        v-for="item in column === 1 ? firstColumnList : secondColumnList"
        :key="item.key"
        style="width: 100%"
      >
        <trace-component-item
          :color-codes="colorCodes"
          :entire-trace-unverified="entireTraceUnverified"
          :[item.property]="item.value"
          :supplyChain="supplyChain"
          :isCompact="true"
          :isMobile="isMobile"
          :deliveryMethods="deliveryMethods"
          :repairs="repairs"
          :resales="resales"
          :recycles="recycles"
          :donations="donations"
          :upcycles="upcycles"
          :isAddReviewVisible="isAddReviewVisible"
          :isPreview="isPreview"
          :styleNumber="styleNumber"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TraceComponentItem from "./TraceComponentItem.vue";

export default {
  name: "TraceCompact",
  components: {
    TraceComponentItem,
  },
  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
    fabricComposition: {
      type: String,
      default: "",
    },
    careInstructions: {
      type: Array,
      default: () => [],
    },
    sustainability: {
      type: String,
      default: "",
    },
    displaySupplyChain: {
      type: Boolean,
      default: false,
    },
    supplyChain: {
      type: Array,
      default: () => [],
    },
    bom: {
      type: Array,
      default: () => [],
    },
    lcaValues: {
      type: Object,
      default: () => ({}),
    },
    isRepairResaleRecycle: {
      type: Boolean,
      default: false,
    },
    repairs: {
      type: Array,
      default: () => [],
    },
    resales: {
      type: Array,
      default: () => [],
    },
    recycles: {
      type: Array,
      default: () => [],
    },
    donations: {
      type: Array,
      default: () => [],
    },
    upcycles: {
      type: Array,
      default: () => [],
    },
    isReviewComponent: {
      type: Boolean,
      default: false,
    },
    isAddReviewVisible: {
      type: Boolean,
      default: false,
    },
    isPreview: {
      type: Boolean,
      default: false,
    },
    styleNumber: {
      type: String,
      default: null,
    },
    deliveryMethods: {
      type: Array,
      default: () => [],
    },
    colorCodes: {
      type: Object,
      default: () => ({
        titleColor: "#473068",
        fontColor: "#473068",
        iconColor: "#70d0ce",
        verifiedColor: "#473068",
        unverifiedColor: "#ea7a66",
        backgroundColor: "#ffffff",
      }),
    },
    entireTraceUnverified: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    componentsList() {
      const list = [];
      if (this.fabricComposition) {
        list.push({
          key: "fabric-composition",
          property: "fabricComposition",
          value: this.fabricComposition,
        });
      }

      if (this.careInstructions && this.careInstructions.length) {
        list.push({
          key: "care-instruction",
          property: "careInstructions",
          value: this.careInstructions,
        });
      }

      if (
        this.displaySupplyChain &&
        this.supplyChain &&
        this.supplyChain.length
      ) {
        list.push({
          key: "supply-chain",
          property: "displaySupplyChain",
          value: this.displaySupplyChain,
        });
      }

      if (this.bom && this.bom.length) {
        list.push({
          key: "bom-item",
          property: "bom",
          value: this.bom,
        });
      }

      if (this.sustainability) {
        list.push({
          key: "sustainability",
          property: "sustainability",
          value: this.sustainability,
        });
      }

      if (this.lcaValues) {
        list.push({
          key: "lca-values",
          property: "lcaValues",
          value: this.lcaValues,
        });
      }

      if (
        this.isRepairResaleRecycle &&
        ((this.repairs && this.repairs.length) ||
          (this.resales && this.resales.length) ||
          (this.recycles && this.recycles.length) ||
          (this.donations && this.donations.length) ||
          (this.upcycles && this.upcycles.length))
      ) {
        list.push({
          key: "repair-resale-and-recycle",
          property: "isRepairResaleRecycle",
          value: this.isRepairResaleRecycle,
        });
      }

      if (this.isReviewComponent) {
        list.push({
          key: "customer-feedback",
          property: "isReviewComponent",
          value: this.isReviewComponent,
        });
      }

      return list;
    },
    firstColumnList() {
      return this.isMobile
        ? this.componentsList
        : this.componentsList.filter((_, index) => index % 2 === 0);
    },
    secondColumnList() {
      return this.isMobile
        ? []
        : this.componentsList.filter((_, index) => index % 2 === 1);
    },
  },
};
</script>

<style scoped>
.compact-container {
  text-align: left !important;
}
.flex-raw {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  gap: calc(100vw * 0.05);
}
.flex-column {
  display: flex;
  flex: 1 1 0px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 5px;
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.fabricComposition)?_c('div',[_c('trace-component-header',{attrs:{"title":"Fabric Composition","icon":"fabric-composition-icon","colorCodes":_vm.colorCodes,"isCompact":_vm.isCompact,"isMobile":_vm.isMobile}},[_c('div',{class:`more-info-data${_vm.isMobile ? '-mobile' : ''} accept-line-breaks`,style:({ color: _vm.colorCodes.fontColor })},[_vm._v(" "+_vm._s(_vm.fabricComposition)+" ")])])],1):(_vm.careInstructions && _vm.careInstructions.length)?_c('div',[_c('trace-component-header',{attrs:{"title":"Care Instructions","icon":"care-instruction-icon","colorCodes":_vm.colorCodes,"isCompact":_vm.isCompact,"isMobile":_vm.isMobile,"iconSize":24}},[_c('div',{class:`more-info-data${_vm.isMobile ? '-mobile' : ''}`,style:({ color: _vm.colorCodes.fontColor })},[_c('div',{staticStyle:{"min-height":"40px","margin-bottom":"10px","width":"85%"}},_vm._l((_vm.careInstructions),function(careInstruction,index){return _c('span',{key:`careInstruction${index}`},[(careInstruction.icon)?_c('img',{staticClass:"care-instruction-img",style:(_vm.careInstructionColorManipulation),attrs:{"src":careInstruction.icon}}):_vm._e()])}),0),_vm._l((_vm.careInstructions),function(careInstruction,index){return _c('div',{key:`careInstruction${index}`},[_c('span',[_vm._v(_vm._s(careInstruction.care_instruction_name))])])})],2)])],1):(_vm.displaySupplyChain && _vm.supplyChain && _vm.supplyChain.length)?_c('div',[_c('trace-component-header',{attrs:{"title":"Supply Chain","icon":"supply-chain-icon","colorCodes":_vm.colorCodes,"isCompact":_vm.isCompact,"isMobile":_vm.isMobile,"iconSize":24}},[_c('div',{style:(`${
        _vm.isMobile || _vm.isCompact
          ? `margin-left: ${_vm.isMobile ? '15' : '74'}px; margin-top: 20px;`
          : 'margin-top: 65px;'
      }`)},_vm._l((_vm.supplyChain),function(trace,index){return _c('div',{key:`trace${index}`},[(trace.factory?.established_country || trace.delivery_method)?_c('div',[_c('div',{staticClass:"icon-container"},[_c('trace-services-icon',{attrs:{"traceName":trace.icon,"variant":_vm.colorCodes.iconColor,"background":_vm.colorCodes.backgroundColor}}),_c('span',{staticClass:"title supply-chain-title",style:({
                color: _vm.colorCodes.titleColor,
              })},[_vm._v(" "+_vm._s(trace.field_name.toUpperCase())+" ")])],1),_c('v-row',[(_vm.stepperShow(index))?_c('div',{class:`stepper${_vm.isMobile ? '-mobile' : ''}`,style:(`border-image-source: linear-gradient(to bottom, ${_vm.colorCodes.iconColor}, ${_vm.iconDividerGradientEndColor}) !important;`)}):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"content"},[(trace.factory)?_c('div',[(trace.factory.established_country)?_c('div',{staticClass:"data-field details-subtitle",style:({
                      color:
                        !_vm.entireTraceUnverified && trace.verified
                          ? _vm.colorCodes.verifiedColor
                          : _vm.colorCodes.unverifiedColor,
                    })},[_vm._v(" Country: "),_c('span',{staticClass:"details-data",style:({
                        color:
                          !_vm.entireTraceUnverified && trace.verified
                            ? _vm.colorCodes.verifiedColor
                            : _vm.colorCodes.unverifiedColor,
                      })},[_vm._v(" "+_vm._s(trace.factory.established_country)+" ")])]):_vm._e(),(trace.factory.established_city)?_c('div',{staticClass:"data-field details-subtitle",style:({
                      color:
                        !_vm.entireTraceUnverified && trace.verified
                          ? _vm.colorCodes.verifiedColor
                          : _vm.colorCodes.unverifiedColor,
                    })},[_vm._v(" City: "),_c('span',{staticClass:"details-data",style:({
                        color:
                          !_vm.entireTraceUnverified && trace.verified
                            ? _vm.colorCodes.verifiedColor
                            : _vm.colorCodes.unverifiedColor,
                      })},[_vm._v(" "+_vm._s(trace.factory.established_city)+" ")])]):_vm._e(),(trace.factory.number_of_employees)?_c('div',{staticClass:"data-field details-subtitle",style:({
                      color:
                        !_vm.entireTraceUnverified && trace.verified
                          ? _vm.colorCodes.verifiedColor
                          : _vm.colorCodes.unverifiedColor,
                    })},[_vm._v(" Employees: "),_c('span',{staticClass:"data"},[_vm._v(_vm._s(trace.factory.number_of_employees)+" "+_vm._s(_vm.formatEmployees(trace.factory)))])]):_vm._e(),(trace.factory?.certifications.length)?_c('div',[_c('div',{staticClass:"data-field details-subtitle",style:({
                        color:
                          !_vm.entireTraceUnverified && trace.verified
                            ? _vm.colorCodes.verifiedColor
                            : _vm.colorCodes.unverifiedColor,
                      })},[_vm._v(" Certifications: ")]),_c('div',{staticClass:"certifications",attrs:{"tabindex":"0"}},_vm._l((trace.factory
                          .certifications),function(certification,index){return _c('div',{key:`trace.certifications${index}`,staticClass:"certification-list",style:(!_vm.entireTraceUnverified && trace.verified
                            ? _vm.verifiedCertificationStyles
                            : _vm.unverifiedCertificationStyles)},[(!certification.description)?_c('div',[_vm._v(" "+_vm._s(certification.caption)+" ")]):_c('v-tooltip',{attrs:{"top":"","color":!_vm.entireTraceUnverified && trace.verified
                              ? _vm.colorCodes.verifiedColor
                              : _vm.colorCodes.unverifiedColor},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',{attrs:{"data-app":""}},[_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_vm._v(" "+_vm._s(certification.caption)+" ")])])]}}],null,true)},[_c('div',{staticClass:"description-child-container"},[_c('div',{staticClass:"mt-1"},[_vm._v(" "+_vm._s(certification.description)+" ")])])])],1)}),0)]):_vm._e()]):(trace.delivery_method)?_c('div',{staticClass:"data-field details-subtitle",style:({
                    color:
                      !_vm.entireTraceUnverified && trace.verified
                        ? _vm.colorCodes.verifiedColor
                        : _vm.colorCodes.unverifiedColor,
                  })},[_vm._v(" Method: "),_c('span',{staticClass:"details-data",style:({
                      color:
                        !_vm.entireTraceUnverified && trace.verified
                          ? _vm.colorCodes.verifiedColor
                          : _vm.colorCodes.unverifiedColor,
                    })},[_vm._v(" "+_vm._s(_vm.deliveryMethods.find( (element) => trace.delivery_method === element.id ).name)+" ")])]):_vm._e()])])],1)],1):_vm._e()])}),0)])],1):(_vm.sustainability)?_c('div',[_c('trace-component-header',{attrs:{"title":"Sustainability and Circularity","icon":"sustainability-icon","colorCodes":_vm.colorCodes,"isCompact":_vm.isCompact,"isMobile":_vm.isMobile,"iconSize":24}},[_c('div',{directives:[{name:"linkified",rawName:"v-linkified"}],class:`more-info-data${_vm.isMobile ? '-mobile' : ''} accept-line-breaks`,style:({ color: _vm.colorCodes.fontColor }),domProps:{"innerHTML":_vm._s(_vm.sustainability)}})])],1):(_vm.lcaValues && Object.keys(_vm.lcaValues).length)?_c('div',[_c('trace-component-header',{attrs:{"title":"Life Cycle Assessments","icon":"l-c-a-icon","colorCodes":_vm.colorCodes,"isCompact":_vm.isCompact,"isMobile":_vm.isMobile,"iconSize":24}},[_c('v-app',[_c('div',{style:(`${_vm.isMobile ? 'margin-top: 30px;' : ''}`)},[_c('div',{staticClass:"lca-flexbox"},[_c('l-c-a-view',{attrs:{"lcaValues":_vm.lcaValues,"isCompact":_vm.isCompact,"isMobile":_vm.isMobile,"colorCodes":_vm.colorCodes}})],1)])])],1)],1):(_vm.repairRecycleResaleHeaders && _vm.repairRecycleResaleHeaders.length)?_c('div',[_c('trace-component-header',{attrs:{"title":"Donation, Repair, Upcycle, Recycle and Resale","icon":"repair-recycle-resale-icon","colorCodes":_vm.colorCodes,"isCompact":_vm.isCompact,"isMobile":_vm.isMobile,"iconSize":24}},[_c('v-app',[_c('div',[_c('div',{class:`${
            _vm.isMobile
              ? !_vm.isCompact
                ? 'repair-resale-recycle-item-flexbox-mobile repair-resale-recycle-margin'
                : 'repair-resale-recycle-item-flexbox-mobile'
              : 'repair-resale-recycle-item-flexbox'
          }`},_vm._l((_vm.repairRecycleResaleHeaders),function(item){return _c('div',{key:item.title,class:`${
              _vm.isMobile || _vm.isCompact
                ? 'repair-resale-recycle-item-wrapper-mobile'
                : 'repair-resale-recycle-item-wrapper'
            }`},[_c('div',{staticClass:"icon-container",staticStyle:{"margin-bottom":"30px"}},[_c('trace-services-icon',{attrs:{"traceName":item.icon,"variant":_vm.colorCodes.iconColor,"background":_vm.colorCodes.backgroundColor}}),_c('span',{staticClass:"title supply-chain-title",style:({
                  color: _vm.colorCodes.titleColor,
                })},[_vm._v(" "+_vm._s(item.title)+" ")])],1),_vm._l((item.data),function(itemData){return _c('div',{key:`${item.type}-${itemData.id}`},[_c('repair-resale-recycle-item',{attrs:{"item":itemData,"type":item.type,"expandWithPopup":!_vm.isMobile,"entireTraceUnverified":_vm.entireTraceUnverified,"colorCodes":_vm.colorCodes}})],1)})],2)}),0)])])],1)],1):(_vm.bom && _vm.bom.length)?_c('div',[_c('trace-component-header',{attrs:{"title":"Component Traceability","icon":"bom-item-icon","colorCodes":_vm.colorCodes,"isCompact":_vm.isCompact,"isMobile":_vm.isMobile,"iconSize":24}},[_c('v-app',[_c('div',{style:(`${_vm.isMobile ? 'margin-top: 30px;' : ''}`)},[_c('div',{class:`${_vm.isMobile ? '' : 'bom-items-flexbox'}`},_vm._l((_vm.bom),function(bomItem){return _c('div',{key:bomItem.id,class:`${
              _vm.isMobile || _vm.isCompact
                ? 'bom-item-wrapper-mobile'
                : 'bom-item-wrapper'
            }`},[_c('bom-item-trace',{attrs:{"bomItem":bomItem,"expandWithPopup":!_vm.isMobile,"entireTraceUnverified":_vm.entireTraceUnverified,"colorCodes":_vm.colorCodes}})],1)}),0)])])],1)],1):(_vm.isReviewComponent)?_c('div',[_c('trace-component-header',{attrs:{"title":"Customer Feedback","icon":"customer-feedback-icon","colorCodes":_vm.colorCodes,"isCompact":_vm.isCompact,"isMobile":_vm.isMobile,"iconSize":24}},[_c('review',{attrs:{"styleNumber":_vm.styleNumber,"isAddReviewVisible":_vm.isAddReviewVisible,"preview":_vm.isPreview,"isMobile":_vm.isMobile,"isCompact":_vm.isCompact,"colorCodes":_vm.colorCodes}})],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }